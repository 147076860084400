import { timesheets } from 'core/timesheets.js'
import { tConvert, user_prefers_12hr } from 'core/helpers/format_helpers.js'
import { msUtil } from 'core/ms_util.js'
/*
 * Model that represents a timesheet row consisting of a Punch record and Shift
 *
 * @param {Integer} userId              User ID
 * @param {Object} punch                Corresponding Punch record
 * @param {Object} shift                Corresponding Shift
 * @param {Object} exceptionRules       Current Department rules for exceptions
 * @param {Boolean} isEditable Boolean for state of Timesheet
 *
 * @return {Object} TimesheetItem instance
 */
export function TimesheetItem(
  id,
  userId,
  punch,
  shift,
  exceptionRules,
  isEditable,
  jobSitesEnabled,
  showBreakPunches,
  paidBreaks,
  photoPunchesEnabled,
  trcEnabled,
  managerApproval,
  shiftBasedPunches,
  payCodeEnabled,
  isReadOnlyTimesheets,
  isEnterpriseCompany
) {
  // returned instance
  var _this = {
    id: id,
    exceptionRules: exceptionRules,
    exceptions: {},
    punch: punch,
    shift: shift,
    isEditable: isEditable,
    userId: userId,
    jobSitesEnabled: jobSitesEnabled,
    showBreakPunches: showBreakPunches,
    paidBreaks: paidBreaks,
    photoPunchesEnabled: photoPunchesEnabled,
    trcEnabled: trcEnabled,
    payCodeEnabled: payCodeEnabled,
    managerApproval: managerApproval,
    shiftBasedPunches: shiftBasedPunches,
    isReadOnlyTimesheets: isReadOnlyTimesheets,
    isEnterpriseCompany: isEnterpriseCompany,

    date: function () {
      return this.punch ? this.punch.punched_in_date : this.shift.start_date
    },

    paidDuration: function () {
      return this.punch ? this.punch.paid_duration : 0
    },

    render: function () {
      return new TimesheetItemBuilder(this)
    },

    validate: function () {
      return validate(this)
    },

    selector: function () {
      return $('tr[data-timesheet-item-id=' + this.id + ']')
    },

    startsAt: function () {
      return this.punch ? this.punch.punched_in_at : this.shift.starts_at
    },

    isLocked: function () {
      if (this.punch && this.punch.locked_at) {
        return true
      } else {
        return false
      }
    },

    update: function (params) {
      $.extend(this, params)
      validate(this)
      if (this.shift || this.punch) {
        timesheets.timesheetEvents.trigger('timesheetItem:update', [this])
      } else {
        timesheets.timesheetEvents.trigger('timesheetItem:destroy', [this])
      }
    },

    entryIdCheck: function () {
      if (!this.isEditable) {
        return ''
      }
      if (this.punch !== null && this.punch.locked_at !== null) {
        return ''
      }
      if (this.punch !== null) {
        return '<input type="checkbox" class="checkAll" value="' + this.punch.id + '" data-type="punch">'
      }
      if (this.shift !== null) {
        return '<input type="checkbox" class="checkAll" value="' + this.shift.id + '" data-type="shift">'
      }
      return ''
    }
  }

  validate(_this)

  /*
   * Validator
   *
   * @param {Object} _this Instance
   */
  function validate(_this) {
    _this.exceptions.count = 0
    _this.exceptions.messages = ''
    _this.exceptions.punchIn = []
    _this.exceptions.punchOut = []
    _this.exceptions.types = []

    if (isEditable) {
      if (_this.punch) {
        if (_this.overtimeSeconds > 0) {
          _this.hasOvertime = true
        }
      }
      if (_this.shift) {
        if (_this.punch) {
          if (
            _this.punch.punched_in_at < _this.shift.starts_at &&
            Math.abs(_this.punch.punched_in_at - _this.shift.starts_at) > _this.exceptionRules.earlyPunchInAllowance
          ) {
            addException(_this, 'punchIn', I18n.t('timesheet.js.in_early'), 'early_clock_in')
          } else if (
            _this.punch.punched_in_at > _this.shift.starts_at &&
            Math.abs(_this.shift.starts_at - _this.punch.punched_in_at) > _this.exceptionRules.latePunchInAllowance
          ) {
            addException(_this, 'punchIn', I18n.t('timesheet.js.in_late'), 'late_clock_in')
          }
          if (
            _this.punch.punched_out_at &&
            _this.punch.punched_out_at > _this.shift.ends_at &&
            Math.abs(_this.punch.punched_out_at - _this.shift.ends_at) > _this.exceptionRules.latePunchOutAllowance
          ) {
            addException(_this, 'punchOut', I18n.t('timesheet.js.out_late'), 'late_clock_out')
          }
          if (_this.punch.punched_in_at && !_this.punch.punched_out_at && moment() > moment(_this.shift.ends_at * 1000)) {
            addException(_this, 'punchOut', I18n.t('timesheet.js.no_clock_out'), 'missing_clock')
          }
        } else if (moment(_this.shift.starts_at * 1000) < moment()) {
          addException(_this, 'punchIn', I18n.t('timesheet.js.no_clock_in'), 'missing_clock')
        }
      } else {
        // no shift
        if (_this.punch) {
          if (_this.punch.punched_out_at) {
            addException(_this, 'punchIn', I18n.t('timesheet.js.in_out_unscheduled'), 'no_shift')
            addException(_this, 'punchOut', I18n.t('timesheet.js.in_out_unscheduled'), 'no_shift')
          } else {
            addException(_this, 'punchIn', I18n.t('timesheet.js.in_unscheduled'), 'no_shift')
          }
        }
      }
    }

    _this.exceptions.messages = _.union(_this.exceptions.punchIn, _this.exceptions.punchOut).join(' ')
  }

  /*
   * Exception collection builder
   *
   * @param {Object} _this    Instance
   * @param {String} target   Exceptions collection key
   * @param {String} message  Exception message
   * @param {String} type     Exception type
   */
  function addException(_this, target, message, type) {
    _this.exceptions.count += 1
    _this.exceptions[target].push(message)
    _this.exceptions.types.push(type)
  }

  return _this
}

/*
 * Dom builder for TimesheetItem
 *
 * @param {TimesheetItem} timesheetItem
 *
 * @return {Object} Table row
 */
function TimesheetItemBuilder(timesheetItem) {
  var tr = '<tr data-timesheet-item-id="' + timesheetItem.id + '" data-approved="' + timesheetItem.punch?.manager_approved + '">'

  if ((timesheetItem.managerApproval || timesheetItem.shiftBasedPunches) && !timesheetItem.isReadOnlyTimesheets) {
    tr += '<td class="text-left">' + timesheetItem.entryIdCheck() + '</td>'
  }
  tr += dateCell()
  tr += '<td class="text-left">' + positionName() + '</td>'
  if (timesheetItem.jobSitesEnabled) {
    tr += '<td class="text-left">' + jobSiteName() + '</td>'
  }
  if (timesheetItem.payCodeEnabled) {
    tr += '<td class="text-left">' + payCode() + '</td>'
  }
  if (timesheetItem.trcEnabled) {
    tr += '<td class="text-left">' + timeReportingCode() + '</td>'
  }
  tr += punchAndShiftTimeCell('In')
  tr += punchAndShiftTimeCell('Out')
  if (!timesheetItem.isEnterpriseCompany) {
    if (timesheetItem.showBreakPunches) {
      tr += breaksListCell()
      tr += breakClockingCell('Start')
      tr += breakClockingCell('End')
    } else {
      tr += breaksDurationTotalCell()
    }
    tr += approvedCell()
    tr += overtimeHoursCell()
    tr += paidHoursCell()
  } else {
    tr += approvedCell()
    tr += dateOfPayCell()
  }
  if (timesheetItem.managerApproval) {
    tr += managerApprovedCell()
  }
  tr += actionsCell()
  tr += '</tr>'

  /*
   * Date and exceptions cell
   * @note Add extra date row in cases where User punched in the night before a Shift started
   *
   * @return {Object} Table cell string
   */
  function dateCell() {
    var td = '<td class="text-left"><span>' + timesheetItem.date() + '</span>'
    if (timesheetItem.punch && timesheetItem.shift && timesheetItem.punch.punched_in_date != timesheetItem.shift.start_date) {
      td += '<br/><small class="muted">' + timesheetItem.shift.start_date + '</small>'
    }

    if (timesheetItem.exceptions.count > 0) {
      td +=
        '<i class="fa fa-warning" data-toggle="tooltip" data-container="body" data-placement="right" title="' +
        timesheetItem.exceptions.messages +
        '"></i>'
    }

    td += '<div class="clr"/>'
    td += '</td>'

    return td
  }

  function jobSiteName() {
    if (timesheetItem.punch) {
      if (timesheetItem.punch.job_site_name) {
        return timesheetItem.punch.job_site_name
      } else {
        return '-'
      }
    } else if (timesheetItem.shift) {
      if (timesheetItem.shift.job_site_name) {
        return timesheetItem.shift.job_site_name
      } else {
        return '-'
      }
    }
  }

  function positionName() {
    if (timesheetItem.punch) {
      if (timesheetItem.punch.position_name != null) {
        return timesheetItem.punch.position_name
      } else {
        return '-'
      }
    } else if (timesheetItem.shift) {
      if (timesheetItem.shift.position_name != null) {
        return timesheetItem.shift.position_name
      } else {
        return '-'
      }
    }
  }

  function timeReportingCode() {
    if (timesheetItem.punch) {
      if (timesheetItem.punch.time_reporting_code) {
        return timesheetItem.punch.time_reporting_code
      } else {
        return '-'
      }
    } else if (timesheetItem.shift) {
      if (timesheetItem.shift.time_reporting_code) {
        return timesheetItem.shift.time_reporting_code
      } else {
        return '-'
      }
    }
  }

  function payCode() {
    if (timesheetItem.punch) {
      if (timesheetItem.punch.pay_code_formatted_name !== null) {
        return timesheetItem.punch.pay_code_formatted_name
      } else {
        return '-'
      }
    } else if (timesheetItem.shift) {
      if (timesheetItem.shift.pay_code_formatted_name !== null) {
        return timesheetItem.shift.pay_code_formatted_name
      } else {
        return '-'
      }
    }
  }
  /*
   * Punch time/Shift time table cell with exception highlight
   *
   * @param {String} punchType 'In' or 'Out'
   *
   * @return {Object} Table cell string
   */
  function punchAndShiftTimeCell(punchType) {
    var td = '<td class="text-center'
    var shiftTime

    if (timesheetItem.exceptions['punch' + punchType].length) {
      td += ' punch-exception'
    }

    // If clock out is empty, add value missing class
    if (punchType == 'Out' && timesheetItem.punch && timesheetItem.punch.punched_out_time === null) {
      td += ' punch-value-missing'
    }

    td += '">'

    if (timesheetItem.shift) {
      if (punchType === 'In') {
        shiftTime = timesheetItem.shift.start_time
      } else {
        shiftTime = timesheetItem.shift.end_time
        shiftTime += startEndDayDiff(timesheetItem.shift.start_date, timesheetItem.shift.end_date)
      }
    } else {
      shiftTime = '-'
    }

    if (timesheetItem.punch) {
      // is there a punch photo? LET'S SEE!
      if (timesheetItem.punch.punch_photos && timesheetItem.punch.punch_photos.length > 0) {
        // Splits photo punches by "in" (index 0) or "out" (index 1)
        var photo_types = _.partition(timesheetItem.punch.punch_photos, (item) => {
          return item.punch_type == 'in'
        })

        var pic

        if (punchType === 'Out') {
          pic = photo_types[1][photo_types[1].length - 1]
        } else {
          pic = photo_types[0][photo_types[0].length - 1]
        }

        if (!_.isUndefined(pic)) {
          td += '<div class="punch-photo">'
          td += '<img src="' + pic.punch_photo + '" />'
          td += '</div>'
        } else {
          td += '<div class="punch-photo">'
          td += '</div>'
        }
      }
      td += '<div class="punch-time-details">'

      td += '<small class="muted">' + shiftTime + '</small><br>'
      td += '<span>'
      if (punchType === 'In') {
        td += user_prefers_12hr() ? tConvert(timesheetItem.punch.punched_in_time) : tConvert(timesheetItem.punch.punched_in_time, true)
        if (timesheetItem.punch.punched_in_date && timesheetItem.punch.actual_in_date) {
          td += startEndDayDiff(timesheetItem.punch.punched_in_date, timesheetItem.punch.actual_in_date)
        }
      } else if (timesheetItem.punch.punched_out_at) {
        td += user_prefers_12hr() ? tConvert(timesheetItem.punch.punched_out_time) : tConvert(timesheetItem.punch.punched_out_time, true)
        td += startEndDayDiff(timesheetItem.punch.punched_in_date, timesheetItem.punch.punched_out_date)
      }
      td += '</span>'
    } else {
      td += '<small class="muted">' + shiftTime + '</small><br>'
    }
    td += '</div></td>'
    return td
  }

  /*
   * Generates a string that shows number of days disparity between start and end date
   *
   * @param [String] startDate
   * @param [String] endDate
   *
   * @return {String} Offset description
   */
  function startEndDayDiff(startDate, endDate) {
    var offset = ''
    if (startDate != endDate) {
      var diff = moment(endDate).diff(startDate, 'days')
      offset = diff > 0 ? ' +' : ' -'
      var absDiff = Math.abs(diff)
      offset += ' ' + absDiff + ' ' + (absDiff > 1 ? I18n.t('timesheet.js.days') : I18n.t('timesheet.js.day'))
    }
    return offset
  }

  /*
   * Total Duration of Breaks
   *
   * @return {Object} Table cell string
   */
  function breaksDurationTotalCell() {
    var td = '<td class="text-center">'

    if (timesheetItem.shift) {
      td += msUtil.durationToHours(timesheetItem.shift.total_breaks, true)
    } else {
      td += '-'
    }

    td += '</td>'

    return td
  }

  /*
   * List of Scheduled Breaks
   *
   * @return {Object} Table cell string
   */
  function breaksListCell() {
    var td = '<td class="text-center">'

    if (timesheetItem.shift) {
      if (timesheetItem.shift.breaks) {
        _.each(timesheetItem.shift.breaks, (scheduled_break) => {
          td += '<div>'
          td += msUtil.durationToMinutes(scheduled_break, true)
          td += '</div>'
        })
      }
    }

    td += '</td>'

    return td
  }

  /*
   * BreakPunch time table cell with exception highlight
   *
   * @param {String} breakType 'In' or 'Out'
   *
   * @return {Object} Table cell string
   */
  function breakClockingCell(breakType) {
    var td = '<td class="text-center">'

    if (timesheetItem.punch) {
      if (timesheetItem.punch.break_punches) {
        _.each(timesheetItem.punch.break_punches, (break_punch) => {
          td += '<div class="break-punch-time-with-photo">'

          if (timesheetItem.photoPunchesEnabled) {
            // is there a punch_photo for this break punch?
            if (break_punch.punch_photos && break_punch.punch_photos.length > 0) {
              var pic
              td += '<span class="break-punch-photo">'

              if (breakType === 'End') {
                pic = break_punch.punch_photos[0]
              }

              if (!_.isUndefined(pic)) {
                td += '<div class="punch-photo">'
                td += '<img src="' + pic.punch_photo + '" />'
                td += '</div>'
              } else {
                td += '<div class="punch-photo">'
                td += '</div>'
              }

              td += '</span>'
            }
          }

          var temp_time = breakType == 'Start' ? break_punch.starts_at_time : break_punch.ends_at_time
          td += '<span class="break-punch-clock-time-with-photo">'

          if (breakType === 'Start') {
            td += user_prefers_12hr() ? tConvert(break_punch.starts_at_time) : tConvert(break_punch.starts_at_time, true)
            if (timesheetItem.punch.punched_in_date && break_punch.starts_at_date) {
              td += startEndDayDiff(timesheetItem.punch.punched_in_date, break_punch.starts_at_date)
            }
          } else if (break_punch.ends_at_date) {
            td += user_prefers_12hr() ? tConvert(break_punch.ends_at_time) : tConvert(break_punch.ends_at_time, true)
            td += startEndDayDiff(timesheetItem.punch.punched_in_date, break_punch.ends_at_date)
          }

          td += '</span>'

          td += '</div>'
        })
      } else {
        td += '<div>'
        td += '-'
        td += '</div>'
      }
    }

    td += '</td>'

    return td
  }

  /*
   * Approved punch cell
   *
   * @return {Object} Table cell string
   */
  function approvedCell() {
    var td = '<td class="text-center">'
    if (timesheetItem.punch && timesheetItem.punch.approved) {
      td += '<i class="fa fa-check"></i>'
    } else {
      td += '-'
    }
    td += '</td>'

    return td
  }

  /*
   * Overtime hours cell
   *
   * @return {String} Table cell HTML string
   */
  function overtimeHoursCell() {
    if (timesheetItem.overtimeSeconds != null) {
      const overtimeHours = msUtil.durationToHours(timesheetItem.overtimeSeconds, true)
      return `<td class="text-center punch-exception"><span class="punch-exception">${overtimeHours}</span></td>`
    } else {
      return '<td class="text-center"><span>-</span></td>'
    }
  }

  /*
   * Paid hours cell with exception highlight
   *
   * @return {Object} Table cell string
   */
  function paidHoursCell() {
    var td = '<td class="text-center'

    if (timesheetItem.exceptions.count > 0) {
      td += ' punch-exception'
    }

    td += '"><span>' + msUtil.durationToHours(timesheetItem.paidDuration(), true) + '</span>'

    if (timesheetItem.isLocked()) {
      td += '<i class="fa fa-lock"></i>'
    }

    td += '</td>'

    return td
  }

  function dateOfPayCell() {
    return `<td class="text-center"><span>${timesheetItem.punch?.date_of_pay || '-'}</span></td>`
  }

  /*
   * Manager Approved
   *
   * @return {Object} Table cell string
   */
  function managerApprovedCell() {
    var td = '<td class="text-center">'
    if (timesheetItem.punch && timesheetItem.punch.manager_approved) {
      td += '<i class="fa fa-check"></i>'
    } else {
      td += '-'
    }
    td += '</td>'

    return td
  }

  /*
   * Actions cell with Edit and History buttons
   *
   * @return {Object} Table cell string
   */
  function actionsCell() {
    var td = '<td class="text-center">'
    if (!timesheetItem.isReadOnlyTimesheets) {
      td +=
        '<button class="edit-timesheet-btn btn btn-gray" ' +
        (!timesheetItem.isEditable ? 'disabled >' : '>') +
        I18n.t('timesheet.js.edit') +
        '</button>'
    }
    td += '<button class="history-timesheet-btn btn" ' + (!timesheetItem.punch ? 'disabled >' : '>') + I18n.t('timesheet.js.history') + '</button>'
    td += '</td>'
    return td
  }

  return $(tr)
}
